<template>
	<div class="login">
		<!-- 输入账号 -->
		<template v-if="isflag">
			<!-- <img src="../../assets/img/login_title.png" style="width: 72%;display: block;margin: auto;padding: 40px 0px 20px;" alt=""> -->
			<!-- <div class="top">登录</div> -->
			<div class="form_box">
				<div style="font-size: 22px;color: black;font-weight: bold;text-align: left;padding: 40px 0px 0px 20px;">用户登录</div>
				<div class="form_row">
					<div class="left">
						<div class="title">
							<label>账</label><label>号</label>
						</div>
					</div>
					<input class="right" type="number" v-model.trim="form.account" placeholder="请输入账号" />
				</div>
				<div class="form_row">
					<div class="left">
						<div class="title">
							<label>密</label><label>码</label>
						</div>
					</div>
					<input class="right" type="text" v-model.trim="form.password" placeholder="请输入密码" />
				</div>
				<div class="form_button">
					<button class="button" type="button" @click="login()">登录</button>
				</div>
			</div>
			<img src="../../assets/img/zbfw.png" style="width: 75%;display: block;margin: auto;" alt="">
		</template>
		<!-- 输入个人信息 -->
		<template v-if="!isflag">
			<div class="top">
				<img v-if="status == 2" src="../../assets/img/teacher_top.png" style="width: 80%;display: block;margin: auto;" alt="">
				<img v-else src="../../assets/img/student_top.png" style="width: 80%;display: block;margin: auto;" alt="">
			</div>
			<div class="form_box">
				<div style="font-size: 22px;color: black;font-weight: bold;text-align: left;padding: 40px 0px 0px 20px;">个人信息资料</div>
				<div class="form_row">
					<div class="left">
						<!-- <img class="icon" src="../../assets/img/zhanghao.png"> -->
						<div class="title">
							<label>姓</label><label>名:</label>
						</div>
					</div>
					<input class="right" type="text" v-model.trim="formInfo.username" placeholder="请输入姓名" />
				</div>
				<div class="form_row">
					<div class="left">
						<!-- <img class="icon" src="../../assets/img/school.png"> -->
						<div class="title">
							<label>学</label><label>校:</label>
						</div>
					</div>
					<!-- <div class="right rightflex" @click="schoolShow = true">
						{{formInfo.schoolName == '' ? '请选择所属学校' : formInfo.schoolName}}
						<div class="rightIcon">
							<img src="../../assets/img/select.png">
						</div>
					</div> -->
					<input class="right" type="text" readonly v-model="formInfo.schoolName" />
				</div>
				<template v-if="status == 1">
					<div class="form_row">
						<div class="left">
							<!-- <img class="icon" src="../../assets/img/grade.png"> -->
							<div class="title">
								<label>年</label><label>级:</label>
							</div>
						</div>
						<!-- <div class="right rightflex" @click="gradeShow = true">
							{{formInfo.grade == '' ? '请选择所属年级' : formInfo.grade}}
							<div class="rightIcon">
								<img src="../../assets/img/select.png">
							</div>
						</div> -->
						<input class="right" type="text" readonly v-model="formInfo.grade" />
					</div>
					<div class="form_row">
						<div class="left">
							<!-- <img class="icon" src="../../assets/img/teacher.png"> -->
							<div class="title">
								<label>辅</label><label>导</label><label>老</label><label>师:</label>
							</div>
						</div>
						<input class="right" type="text" v-model.trim="formInfo.teacher" placeholder="请输入老师姓名" />
					</div>
				</template>
				<div class="form_button">
					<button class="button" type="button" @click="toNotice(type)">确认</button>
				</div>
				<div class="tips" v-if="type == 1">
					信息填写完后将不可修改，请认真检查
				</div>
			</div>
			<img src="../../assets/img/zbfw.png" style="width: 75%;display: block;margin: auto;" alt="">
		</template>
		<!-- 学校弹窗 -->
		<!-- <van-popup v-model="schoolShow" position="bottom">
			<van-picker show-toolbar :columns="schoolList" value-key="name" @cancel="onSchoolCancel"
				@confirm="onSchoolConfirm" />
		</van-popup> -->
		<!-- 年级弹窗 -->
		<!-- <van-popup v-model="gradeShow" position="bottom">
			<van-picker show-toolbar :columns="gradeList" value-key="name" @cancel="onGradeCancel"
				@confirm="onGradeConfirm" />
		</van-popup> -->
	</div>
</template>

<script>
	import {
		Login,
		getRegister
	} from '@/api/index'
	export default {
		name: "login",
		data() {
			return {
				form: {
					account: '', //账号
					password: '', //密码
				},
				isflag: true, // true：输入账号状态，false：输入信息状态
				formInfo: {
					user_id: '',
					username: '',
					schoolName: '',
					grade: '',
					teacher: ''
				},
				status: 0, //身份
				schoolShow: false, //学校弹窗
				schoolList: [],
				gradeShow: false, //年级弹窗
				gradeList: [],
				type: '', //type = 1:正式答题 =2:领取奖状
			}
		},
		mounted() {
			// console.log(this.$route.query.type)
			this.type = this.$route.query.type
		},
		methods: {
			login() {
				if (this.form.account == '' || this.form.password == '') {
					this.$toast("请检查账号与密码是否正确")
				} else {
					Login(
						this.form
					).then(res => {
						if (res.data.code == 1) {
							// 清除答题题目记录
							sessionStorage.removeItem('formalAnswer')
							console.log(res)
							// 获取token
							localStorage.setItem('token', res.data.data.userinfo.token)
							// is_register==0 && this.type == 1 未添写信息 正式答题
							if (res.data.data.userinfo.is_register == 0 && this.type == 1) {
								this.isflag = false;
								// 身份显示,1：学生，2：老师
								this.status = res.data.data.userinfo.level;
								// 用户id
								this.formInfo.user_id = res.data.data.userinfo.user_id;
								let {
									grade
								} = res.data.data.userinfo;
								// 学校信息
								this.formInfo.schoolName = grade.school.name;
								// 班级信息
								this.formInfo.grade = grade.name;
							} else if (res.data.data.userinfo.is_register == 1 && this.type == 1) {
								this.$router.push('/notice')
							} else if (res.data.data.userinfo.is_answer == 0 && this.type == 2) {
								//is_answer == 0:未修改 ==1：已修改  && type ==2
								this.isflag = false
								// 身份显示,1：学生，2：老师
								this.status = res.data.data.userinfo.level;
								// 用户id
								this.formInfo.user_id = res.data.data.userinfo.user_id;
								let {
									grade
								} = res.data.data.userinfo;
								// 姓名
								this.formInfo.username = res.data.data.userinfo.nickname;
								// 学校信息
								this.formInfo.schoolName = grade.school.name;
								// 班级信息
								this.formInfo.grade = grade.name;
								// 辅导老师
								this.formInfo.teacher = res.data.data.userinfo.teacher;
							} else if (res.data.data.userinfo.is_answer == 1 && this.type == 2) {
								this.$router.push('/awards')
							}
						} else {
							this.$toast.fail(res.data.msg)
						}
					})
				}
			},
			toNotice(type) {
				console.log(type, "类型")
				if (this.formInfo.username == '') {
					this.$toast("请输入姓名")
				} else if (this.formInfo.teacher == '' && this.status == 1) {
					this.$toast("请输入辅导老师姓名")
				} else {
					let formInfo = '';
					if (this.status == 1) {
						formInfo = {
							user_id: this.formInfo.user_id,
							nickname: this.formInfo.username,
							teacher: this.formInfo.teacher,
						}
					} else if (this.status == 2) {
						formInfo = {
							user_id: this.formInfo.user_id,
							nickname: this.formInfo.username,
						}
					}
					console.log(formInfo, '填写的信息')
					getRegister(formInfo).then(res => {
						console.log(res)
						if (res.data.code == 1 && res.data.jointime != '') {
							if (type == 1) {
								this.$router.push('/notice')
							} else if (type == 2) {
								this.$router.push('/awards')
							}
						} else {
							this.$toast.fail(res.data.msg)
						}
					})
				}
			},

			// 学校班级信息从选择变成后端返回 暂时不要
			/* onSchoolConfirm(value, index) {
				this.formInfo.schoolName = value.name;
				// 获取学校id
				this.formInfo.schoolId = value.id
				this.schoolShow = false;
				console.log(`当前值：${value.name}, 当前索引：${index}`)
			},
			onSchoolCancel() {
				// 关闭学校弹窗
				this.schoolShow = false;
			},
			onGradeCancel() {
				// 关闭年级弹窗
				this.gradeShow = false;
			},
			onGradeConfirm(value, index) {
				this.formInfo.grade = value.name;
				// 获取班级id
				this.formInfo.gradeId = value.id
				this.gradeShow = false;
				console.log(`当前值：${value.name}, 当前索引：${index}`)
			} */
		}
	}
</script>

<style scoped lang="scss">
	.login {
		width: 100%;
		height: 100%;
		min-height: 100vh;
		// background: url(../../assets/img/loginbg.png) no-repeat center;
		background-size: 100% 100%;
		padding-bottom: 30px;
		.top {
			width: 100%;
			height: 300px;
			text-align: center;
			font-size: 80px;
			color: #fff;
			line-height: 450px;
			letter-spacing: 20px;
			text-indent: 30px;
			padding-top: 80px;
		}

		.form_box {
			width: 90%;
			height: 1000px;
			background: #fff;
			margin: 0 auto 40px;
			padding: 0 0 40px 0;
			text-align: center;
			border-radius: 20px;
			overflow-y: scroll;
			.form_row {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				padding: 25px 0px 0px;
				margin: auto;
				width: 85%;
				.left {
					width: 28%;
					font-size: 26px;
					font-weight: 600;
					display: flex;
					align-items: center;
					justify-content: space-between;

					.icon {
						width: 40px;
						height: 40px;
					}

					.title {
						width: 70px;
						height: 83px;
						padding-left: 10px;
						display: flex;
						align-items: center;
						justify-content: space-between;
					}
				}

				.right {
					margin: auto;
					width: 95%;
					height: 80px;
					border-radius: 15px;
					padding: 8px 15px;
					font-size: 26px;
					margin-left: 5px;
					text-align: left;
					color: #a0a2a5 !important;
					line-height: 80px;
					background-color: #f1f1f1;
					::placeholder{
						font-size: 20px;
					}
				}

				.rightflex {
					display: flex;
					justify-content: space-between;

					.rightIcon {
						width: 25px;
						height: 25px;

						img {
							width: 100%;
							height: 100%;
						}
					}
				}
			}

			.form_button {
				.button {
					width: 80%;
					height: 70px;
					line-height: 70px;
					background: #55ACEE;
					border: 0px;
					border-radius: 10px;
					margin-top: 100px;
					color: #fff;
					letter-spacing: 10px;
					text-indent: 10px;
					font-size: 30px;
				}
			}

			.tips {
				font-size: 20px;
				color: red;
				margin-top: 30px;
			}
		}
	}

	::v-deep .van-picker__toolbar {
		height: 44PX;
	}

	::v-deep .van-picker__cancel {
		font-size: 16PX;
	}

	::v-deep .van-picker__confirm {
		font-size: 16PX;
	}
</style>
