import request from '../utils/request'

// 登录接口
export function Login(params) {
	return request.post('/api/user/wxLogin', params)
}

//个人信息资料填写
export function getRegister(params, flag) {
	return request.post('/api/user/register', params, true)
}

//所有街区学校信息
export function getSchoolInfo() {
	return request.get('/api/index/schoolInfo')
}

//学校班级信息
export function getClassList(parmas) {
	return request.post('/api/index/classInfo',parmas)
}

//获取答题历史记录
export function getExam() {
	return request.get('/api/user/examList')
}

//获取答题详情
export function getDetail(params) {
	return request.post('/api/user/examDetail',params)
}

//获取街区学校年级班级
export function getCategory(params) {
	return request.post('api/user/getCategory', params)
}

//用户信息
export function getInfo(params) {
	return request.post('/api/user/index', params)
}

//模拟练习
export function getExercise() {
	return request.get('/api/exam/exercise')
}

//正式答题(题目列表)
export function getStartExam(params) {
	return request.post('/api/exam/startExam', params)
}

//正式答题时间
export function getTime(params) {
	return request.post('/api/exam/getStartTime', {
		params
	})
}

//模拟答题
export function getExerciseAnswer(params) {
	return request.post('/api/exam/exerciseAnswer', params)
}

//答题
export function getAnswer(params) {
	return request.post('/api/exam/answer', params)
}

//排行榜
export function getRankInfo(params) {
	return request.post('/api/exam/rankInfo', params)
}

//查排名方式登录
export function getLogin(params) {
	return request.post('/api/user/login', params)
}

//人数
export function getNum(params) {
	return request.post('/api/index/getRegisterCount', params)
}

//奖状
export function uploadData(params) {
	return request.get('/api/exam/getAwardImage?token=' + localStorage.getItem('token'), params)
}

//更新信息
export function profileData(params) {
	return request.post('/api/user/profile', params)
}

//判断是否关注公众号
export function getFollow(params) {
	return request.get('/api/user/isFollow?token=' + localStorage.getItem('token'), params)
}